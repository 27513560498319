import { Pipe, PipeTransform } from '@angular/core';
import { State } from '@models/state';

@Pipe({
  name: 'stateLabel',
  standalone: true,
})
export class StateLabelPipe implements PipeTransform {
  transform(value: string | boolean): string {
    const state = State.find(params => params.value === value);
    let className = '';

    switch (value) {
      case 'sent':
      case 'wip':
      case 'disabled':
      case 'refunded':
      case false:
        className = 'disabled';
        break;
      case 'won':
      case 'ANSWERED':
      case 'active':
      case 'delivered':
      case 'acknowledged':
      case true:
        className = 'activated';
        break;
      case 'enquired':
      case 'unprocessed':
      case 'paid':
      case 'to_sync':
      case 'synced':
      case 'processing':
        className = 'paid';
        break;
      case 'NOT_ANSWERED':
      case 'lost':
      case 'deleted':
      case 'cancelled':
        className = 'deleted';
        break;
    }

    return `<span class="badge ${className}"><i class="icon ${state.icon} me-2"></i><span>${state.label}</span></span>`;
  }
}
